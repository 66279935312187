export const references = [
  {
    content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In: ',
    source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.',
  },
  {
    content: 'American Psychiatric Association.',
    source: 'Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition (DSM-5).',
    info: 'Arlington, VA: American Psychiatric Association; 2013.',
  },
  {
    content: 'Johns MW. A new method for measuring daytime sleepiness: the Epworth sleepiness scale. ',
    source: 'Sleep. ',
    info: '1991;14(6):540-545.',
  },
  {
    content: 'Bassetti CL. Spectrum of narcolepsy. In: ',
    source: 'Baumann CR, Bassetti CL, Scammell TE, eds. Narcolepsy: Pathophysiology, Diagnosis, and Treatment. ',
    info: 'New York, NY: Springer Science+Business Media; 2011:309-319.',
  },
]