import React, { useEffect, useState } from 'react'

import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import { references } from '@components/references/diagnostic-criteria-references'
import DiagnosticExpandibleComponent from '@components/diagnostic-expandible/diagnostic-expandible.component'
import ReadNext from '@components/read-next-button/read-next-button'
import LearnMoreComponent from '@components/learn-more/learn-more.component'

import './diagnostic-criteria.scss'

const DiagnosticCriteria = ({ location }) => {
  const [screenWidth, setScreenWidth] = useState()

  const learnMoreContent = {
    className: 'learn-more-wrapper-gray',
    title: 'Screening tools may help with symptom assessment',
    supText: '3,4',
    link: '/screening-and-diagnosis/screeners/',
    linkLabel: 'View the tools',
    tracking: 'diagnostic criteria,	click,	adult screeners - diagnostic criteria ',
  }

  const handleWindowResize = () => {
    setScreenWidth(window.innerWidth)
  }
  useEffect(() => {
    setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
  })
  const readNext = {
    readNextContent: 'Diagnostic process',
    goTo: '/screening-and-diagnosis/diagnostic-process/',
    tracking: 'diagnostic criteria, click, diagnostic process - diagnostic criteria',
  }

  const collapsibleContent1 = {
    title1: (
      <h2>
        ICSD-3 diagnostic criteria<sup>1</sup>
      </h2>
    ),
    srcContent: (
      <p>
        <em>The American Academy of Sleep medicine (AASM) International Classification of Sleep Disorders - Third Edition (ICSD-3)</em> contains diagnostic criteria for sleep disorders, including narcolepsy.
      </p>
    ),
    blueTitle1: <h3 className="blue-title">Narcolepsy type 1</h3>,
    content1: (
      <div className="seperate-bullet">
        <span>•</span>
        <p> Excessive daytime sleepiness daily for ≥3 months<sup>*</sup></p>
      </div>
    ),
    // criteria: <div>Criteria A and B must be met:</div>,
    content2: (
      <div className="content-ul">
        <div className="seperate-bullet">
          <span>•</span>
          <p> One or both of the following:</p>
        </div>
        <div className="indent">
          <div className="seperate-bullet">
            <span>&ndash;</span>
            <p>
              {' '}
              Cataplexy and mean sleep latency ≤8 minutes and ≥2 SOREMPs on MSLT; SOREMP (≤15 min after sleep onset) on
              preceding nocturnal PSG may replace one of the SOREMPs on MSLT<sup>†</sup>
            </p>
          </div>
          <div className="seperate-bullet">
            <span>&ndash;</span>
            <p>
              {' '}
              Low or absent CSF hypocretin-1 levels<sup>‡</sup>
            </p>
          </div>
        </div>
      </div>
    ),
    blueTitle2: <h3 className="blue-title">Narcolepsy type 2</h3>,
    content3: (
      <div className="seperate-bullet">
        <span>•</span>
        <p>
          Excessive daytime sleepiness and MSLT findings as above, but without cataplexy<sup>§</sup>
        </p>
      </div>
    ),
    content4: (
      <div className="seperate-bullet">
        <span>•</span>
        <p>
        CSF hypocretin-1 levels are unknown or are above the threshold for narcolepsy type 1<sup className='sup-spacing-fix'>§</sup>
        </p>
      </div>
    ),
    content5: (
      <div className="seperate-bullet">
        <span>•</span>
        <p>
          The hypersomnolence and/or MSLT findings are not better explained by other causes, such as insufficient sleep,
          OSA, delayed sleep phase disorder, or the effect of medications or substances or their withdrawal
        </p>
      </div>
    ),
    notes: (
      <div className="notes">
        <div className="top-notes">
          <p>
            *In young children, narcolepsy may sometimes present as excessively long night sleep or as
            resumption of previously discontinued daytime napping.
          </p>
        </div>
        <div className="top-notes">
          <p>
            †If narcolepsy type 1 is strongly suspected clinically, but MSLT criteria are not met, consider
            repeating the MSLT.
          </p>
        </div>
        <div className="top-notes">
          <p>
            ‡Low or absent CSF hypocretin-1 levels = CSF hypocretin-1 concentration measured by immunoreactivity is either
            ≤110 pg/mL or &lt;1/3 of mean values in healthy subjects using the same assay.
          </p>
        </div>
        <div className="top-notes">
          <p>
            §If cataplexy develops later or low or absent CSF hypocretin-1 levels are discovered, reclassify as narcolepsy
            type 1.
          </p>
        </div>
        <div className="top-notes">
          <p>
            CSF, cerebrospinal fluid; ICSD, International Classification of Sleep Disorders; MSLT, multiple sleep
            latency test; PSG, polysomnography; SOREMP, sleep-onset REM period.
          </p>
        </div>
      </div>
    ),

    tracking: 'diagnostic criteria,	click,	look deeper - narcolepsy type 1',
  }
  const collapsibleContent2 = {
    title1: (
      <h2>
        DSM-5 diagnostic criteria<sup>2</sup>
      </h2>
    ),
    srcContent: (
      <p>
        <em>The American Psychiatric Association's Diagnostic and Statistical Manual of Mental Disorders, Fifth Edition
        (DSM-5)</em> contains diagnostic criteria for sleep-wake disorders, including narcolepsy, designed for use by mental
        health and medical clinicians.
      </p>
    ),
    content1: (
      <div className="seperate-bullet">
        <span>•</span>
        <p>
          Recurrent periods of an irrepressible need to sleep, lapsing into sleep, or napping occurring within the same
          day. These must have been occurring ≥3 times per week over the past 3 months
        </p>
      </div>
    ),
    content2: (
      <div className="seperate-bullet">
        <span>•</span>
        <p>The presence of at least one of the following:</p>
      </div>
    ),
    content3: (
      <ol type="1">
        <li>
          Episodes of cataplexy, defined as either (a) or (b), occurring at least a few times per month:
          <p />
          <ol type="a">
            <li>
              In individuals with long-standing disease, brief (seconds to minutes) episodes of sudden bilateral loss of
              muscle tone with maintained consciousness that are precipitated by laughter or joking
            </li>
            <li>
              In children or in individuals within 6 months of onset, spontaneous grimaces or jaw-opening episodes with
              tongue thrusting or a global hypotonia, without any obvious emotional triggers
            </li>
          </ol>
        </li>
        <li>
          Hypocretin deficiency, as measured using CSF hypocretin-1 immunoreactivity values (≤1/3 of values obtained in
          healthy subjects tested using the same assay, or ≤110 pg/mL). Low CSF levels of hypocretin-1 must not be
          observed in the context of acute brain injury, inflammation, or infection
        </li>
        <li>
          Nocturnal sleep PSG showing REM sleep latency ≤15 minutes, or an MSLT showing a mean sleep latency ≤8 minutes
          and ≥2 SOREMPs
        </li>
      </ol>
    ),
    notes: (
      <div className="notes">
        <p className="top-notes">DSM, Diagnostic and Statistical Manual of Mental Disorders.</p>
      </div>
    ),
    tracking: 'diagnostic criteria,	click,	look deeper - narcolepsy type 2',
  }

  return (
    <Layout addedClass='page-diagnostic-criteria' location={location}>
      <div
        className="diagnostic-page-header"
        role="img"
        alt="Clouds young man walking rocky terrain carrying boulders on back "
      >
        <div className="primary-container">
          <div className="diagnostic-header-text">
            <h1>Evaluating Patients for Narcolepsy</h1>
            <p>
              There are 2 separate sets of diagnostic criteria that can be used to evaluate patients for narcolepsy.
              <sup>1,2</sup>
            </p>
          </div>
        </div>
      </div>
      <div className="gray-wrapper">
        <section className="section-gradient" />
        <div className="primary-container">
          <div className="info-section-header diagnostic-padding">
            <div className="section-header">
              <div className="header-warpper ">
                <h2 className="diagnostic-position">Diagnostic Criteria:</h2>
              </div>
              <LearnMoreComponent learnMoreContent={learnMoreContent} />
            </div>
          </div>
          <div className="info-card-wrapper">
            <DiagnosticExpandibleComponent collapsibleCardContent={collapsibleContent1} mobile={screenWidth < 550} />
          </div>
          <div className="info-card-wrapper">
            <DiagnosticExpandibleComponent collapsibleCardContent={collapsibleContent2} mobile={screenWidth < 550} />
          </div>
          <br />
          <ReadNext readNext={readNext} />
        </div>
      </div>
      <ReferenceComponent referenceList={references} />
    </Layout>
  )
}

export default DiagnosticCriteria
